import React from "react";
import Teen20 from "./resultModals/Teen20";
import TeenpattiOpen from "./resultModals/TeenpattiOpen";
import Teen9 from "./resultModals/Teen9";
import Teenpatti1Day from "./resultModals/Teenpatti1Day";
import SuperOver from "./resultModals/SuperOver";
import Race20 from "./resultModals/Race20";
import AB2 from "./resultModals/AndarBahar2";
import DragonTiger2020 from "./resultModals/2020DragonTiger";
import Baccarat from "./resultModals/Baccarat";
import Baccarat2 from "./resultModals/Baccarat2";
import Lucky7B from "./resultModals/Lucky7B";
import CasinoWar from "./resultModals/CasinoWar";
import Cmeter from "./resultModals/Cmeter";
import Card32 from "./resultModals/Card32";
import Card32B from "./resultModals/Card32B";
import Queen from "./resultModals/Queen";
import Dt1day from "./resultModals/Dt1day";
import Poker2020 from "./resultModals/Poker2020";
import InstantWorli from "./resultModals/InstantWorli";
import AAA from "./resultModals/AAA";
import BTable from "./resultModals/BTable";
import AndarBahar from "./resultModals/AndarBahar";
import Cricket20 from "./resultModals/Cricket20";
import Lucky7Aa from "./resultModals/Lucky7A";

export default function GenericIndividualResult({
  data,
  shortName,
  onClose,
  GAME_ID,
}) {
  console.log("shortName", shortName);
  console.log("GAME_ID", GAME_ID);
  const renderResult = () => {
    switch (shortName || GAME_ID) {
      case "teenpatti20":
        return <Teen20 data={data} />;
      case "teenpattiopen":
        return <TeenpattiOpen data={data} />;
      case "teenpattitest":
        return <Teen9 data={data} />;
      case "teenpatti1day":
        return <Teenpatti1Day data={data} />;
      case "super-over":
        return <SuperOver data={data} />;
      case "race20":
        return <Race20 data={data} />;
      case "ab2":
        return <AB2 data={data} />;
      case "dt20":
        return <DragonTiger2020 data={data} />;
      case "baccarat":
        return <Baccarat data={data} />;
      case "baccarat baccarat2game":
        return <Baccarat2 data={data} />;
      // case "lucky7acards":
      //   return <Lucky7B data={data} />;
      case "casino-war":
        return <CasinoWar data={data} />;
      case "cmeter":
        return <Cmeter data={data} />;
      case "cards32a":
        return <Card32 data={data} />;
      case "cards32a cards32b":
        return <Card32B data={data} />;
      case "queen":
        return <Queen data={data} />;
      case "dt1day":
        return <Dt1day data={data} />;
      case "cards32a poker-20":
        return <Poker2020 data={data} />;
      case "worli":
        return <InstantWorli data={data} />;
      case "bollywood":
        return <BTable data={data} />;
      case "aaa":
        return <AAA data={data} />;
      case "ab ab3":
        return <AndarBahar data={data} />;
      case "cricket20":
        return <Cricket20 data={data} />;

      case "lucky7acards":
        // Differentiate by GAME_ID
        if (GAME_ID === "LUCKY7") {
          return <Lucky7Aa data={data} />;
        } else {
          return <Lucky7B data={data} />;
        }

      default:
        return renderDefaultResult();
    }
  };
  // if (GAME_ID === "LUCKY7") {
  //   return <Lucky7Aa data={data} />;
  // }

  const renderDefaultResult = () => {
    return (
      <div className="modal-body">
        <h1>Hello</h1>
      </div>
    );
  };

  const getModalTitle = () => {
    switch (shortName || GAME_ID) {
      case "teenpatti20":
        return "Teen Patti Result";
      case "teenpattiopen":
        return "Teenpatti Open Result";
      case "teenpattitest":
        return "Teenpatti Test Result";
      case "teenpatti1day":
        return "Teenpatti 1 Day Result";
      case "super-over":
        return "Super Over";
      case "race20":
        return "Race 20";
      case "ab2":
        return "Andar Bahar 2";
      case "dt20":
        return "2020 DRAGON TIGER 2";
      case "baccarat":
        return "Baccarat";
      case "baccarat baccarat2game":
        return "Baccarat2";
      // case "lucky7acards":
      //   return "Lucky 7 - B";
      case "casino-war":
        return "CasinoWar";
      case "cmeter":
        return "Casino Meter";
      case "cards32a":
        return "Card 32";
      case "cards32a cards32b":
        return "Card 32 B";
      case "queen":
        return "Casino Queen";
      case "dt1day":
        return "Dragon Tiger 1 Day";
      case "cards32a poker-20":
        return "Poker 2020";
      case "worli":
        return "Instant Worli";
      case "aaa":
        return "Amar Akbar Anthony";
      case "bollywood":
        return "Bollywood Casino";
      case "ab ab3":
        return "Andar Bahar";
      case "cricket20":
        return "Cricket Match 20";
      case "LUCKY7":
        return "Lucky 7 - A";

        case "lucky7acards":
          // Differentiate by GAME_ID
          if (GAME_ID === "LUCKY7") {
            return "Lucky 7 - A";
          } else {
            return "Lucky 7 - B";
          }
      default:
        return "Game Result";
    }
  };
  // if (GAME_ID === "LUCKY7") {
  //   return "Lucky 7 - A";
  // }

  return (
    <div
      role="dialog"
      aria-modal="true"
      className="fade modal show"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{getModalTitle()}</div>
            <button
              onClick={onClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
          {renderResult()}
        </div>
      </div>
    </div>
  );
}
