import React from "react";

export default function Dt1day({ data }) {
  const casinoResult = data?.casinoIndividualResultDTO;

  if (!casinoResult) {
    return <div>No data available</div>;
  }

  // Helper function to get card images
  const getCardImage = (card) =>
    `https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.jpg`;

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        {/* Round ID and Match Time */}
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {casinoResult.marketId}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {new Date(casinoResult.createdAt).toLocaleString()}
          </span>
        </div>

        {/* Dragon and Tiger Cards */}
        <div className="row mt-2">
          {casinoResult.casinoCardResultMaps.map((cardResult) => (
            <div key={cardResult.id} className="col-md-6 text-center">
              <h4 className="result-title">{cardResult.resultFor}</h4>
              <div className="casino-result-cards">
                {cardResult.isWinning && (
                  <div className="casino-winner-icon">
                    <i className="fas fa-trophy" />
                  </div>
                )}
                <img src={getCardImage(cardResult.result[0])} alt="Card" />
              </div>
            </div>
          ))}
        </div>

        {/* Result Details */}
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              {casinoResult.casinoIndividualResultMaps.map((result) => (
                <div key={result.id} className="casino-result-desc-item">
                  <div>{result.resultFor}</div>
                  <div>{result.result[0]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
