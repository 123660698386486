import React from "react";

export default function Cricket20({ data }) {
  // Safely extract the dynamic data with fallback values
  const casinoIndividualResultDTO = data?.casinoIndividualResultDTO || {};
  const casinoCardResultMaps =
    casinoIndividualResultDTO?.casinoCardResultMaps || [];
  const casinoIndividualResultMaps =
    casinoIndividualResultDTO?.casinoIndividualResultMaps || [];

  const { id = "N/A", createdAt = "" } = casinoIndividualResultDTO;

  const cardImage =
    casinoCardResultMaps.length > 0 ? casinoCardResultMaps[0].result[0] : "N/A";
  const resultFor =
    casinoIndividualResultMaps.length > 0
      ? casinoIndividualResultMaps[0].resultFor
      : "Unknown";
  const resultValue =
    casinoIndividualResultMaps.length > 0
      ? casinoIndividualResultMaps[0].result[0]
      : "0";

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {id}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {createdAt ? new Date(createdAt).toLocaleString() : "Unknown"}
          </span>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 text-center">
            <div className="casino-result-cards">
              {/* Displaying the dynamic card image */}
              <img
                src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${cardImage}.jpg`}
                alt={cardImage}
              />
            </div>
          </div>
        </div>
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>{resultFor}</div>
                <div>{resultValue}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
