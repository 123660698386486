import React from "react";

export default function Lucky7Aa({ data }) {
  // Destructure relevant data
  const {
    casinoIndividualResultDTO: {
      id: roundId,
      createdAt,
      casinoIndividualResultMaps = [],
      casinoCardResultMaps = [],
    } = {},
  } = data;

  // Get card image URL
  const cardImageURL = casinoCardResultMaps[0]?.result[0]
    ? `https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${
        casinoCardResultMaps[0].result[0]
      }.jpg`
    : null;

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {roundId || "N/A"}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {createdAt ? new Date(createdAt).toLocaleString() : "N/A"}
          </span>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 text-center">
            {cardImageURL && (
              <div className="casino-result-cards">
                <img src={cardImageURL} alt="Card Result" />
              </div>
            )}
          </div>
        </div>
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              {casinoIndividualResultMaps.map((item) => (
                <div className="casino-result-desc-item" key={item.id}>
                  <div>{item.resultFor}</div>
                  <div>{item.result.join(", ")}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
