import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAnnouncementAPI, searchAPI } from "../../service/AuthService";
import ExposureTable from "../../components/ExposureTable";
import Rules from "../../components/Rules";
import GlobalRules from "../../components/GlobalRules";
import moment from "moment";
const Header = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [announcement, setAnnouncement] = React.useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const componentRef = useRef(null);
  const [isRuleExpanded, setIsRuleExpanded] = useState(false);
  const [isExposureTableOpen, setIsExposureTableOpen] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const handleSearch = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value?.length < 3) {
      return;
    }
    const { response, code } = await searchAPI(value);
    if (code == 200) {
      setSearchResults([
        ...response.cricket?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
        ...response.tennis?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
        ...response.soccer?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
      ]);
    }
  };
  const handleUsernameClick = () => {
    setIsExpanded(!isExpanded);
  };
  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };
  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    getAnnouncement();
  }, []);
  return (
    <section className="header">
      {showRules && (
        <GlobalRules showModal={showRules} setShowModal={setShowRules} />
      )}
      <div
        style={{
          backgroundColor: "black",
        }}
        className="header-top"
      >
        <div className="logo-header">
          <a className="d-xl-none" href="/home">
            <i className="fas fa-home me-1" />
          </a>
          <a href="/home">
            <img src="/logo.png" />
          </a>
        </div>

        <div className="user-details">
          {window?.innerWidth > 786 && (
            <div className="search-box-container ">
              <div className="search-box">
                <input
                  type="search"
                  placeholder="Search here"
                  className={
                    "form-control " +
                    (isSearchOpen ? "search-input-show" : "")
                  }
                  defaultValue=""
                  onChange={handleSearch}
                />

                <a
                  onClick={() => {
                    setIsSearchOpen(!isSearchOpen);
                    setSearchResults([]);
                  }}
                >
                  <i className="fas fa-search-plus" />
                </a>
                {searchResults?.length > 0 && (
                  <div className="search-list">
                    {searchResults?.map((item, index) => (
                      <a
                        href={`/game/${item?.sport?.toLowerCase()}/${
                          item.id
                        }`}
                      >
                        <div className="search-list-item">
                          <div className="search-tournament-name">
                            <b>{item?.eventName}</b>
                          </div>
                          <div className="search-game-date">
                            {moment(item?.eventDate).format(
                              "DD/MM/YYYY HH:MM:SS "
                            )}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <div className="news">
                <marquee scrollamount={3}>
                  {announcement?.announcement}
                </marquee>
              </div>
              <div className="depowith" />
            </div>
          )}
          <div className="header-rules ms-3 ">
            <div>
              <a
                onClick={() => {
                  setShowRules(true);
                }}
                className="rules-link pointer"
              >
                <b>Rules</b>
              </a>
            </div>
          </div>

          <div className="user-balance ms-1 ms-xl-3">
            <div>
              <span>Balance:</span>
              <b>{userData?.balance}</b>
            </div>
            <div>
              <span>Exp:</span>
              <b
                onClick={() => setIsExposureTableOpen(true)}
                className="pointer"
              >
                {userData?.liability}
              </b>
              {window?.innerWidth < 786 && (
                <div className="dropdown ">
                  <a data-toggle="dropdown" className="dropdown-toggle">
                    <u>{userData?.username}</u>
                    <i className="fas fa-chevron-down ms-1" />
                  </a>
                  <div
                    style={{
                      float: "right",
                    }}
                    className="dropdown-menu pookie"
                  >
                    <a
                      href="/reports/accountstatement"
                      className="dropdown-item"
                    >
                      Account Statement
                    </a>
                    {userData?.isUser && (
                      <a href="/reports/profitloss" className="dropdown-item">
                        Profit Loss Report
                      </a>
                    )}
                    {userData?.isUser && (
                      <a href="/reports/bethistory" className="dropdown-item">
                        Bet History
                      </a>
                    )}
                    <a
                      href="/reports/unsetteledbet"
                      className="dropdown-item"
                    >
                      Current Bet
                    </a>
                    <a
                      href="/reports/casinoresults"
                      className="dropdown-item"
                    >
                      Casino Results
                    </a>
                    <a
                      href="/setting/changebtnvalue"
                      className="dropdown-item"
                    >
                      Set Button Values
                    </a>

                    {userData?.isUser && (
                      <a
                        href="/settings/security-auth"
                        className="dropdown-item"
                      >
                        Security Auth Verification
                      </a>
                    )}
                    {userData?.isUser && (
                      <a
                        href="/setting/changepassword"
                        className="dropdown-item"
                      >
                        Change Password
                      </a>
                    )}
                    {/*    <a href="#" className="dropdown-item">
                    Balance
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck"
                        className="custom-control-label"
                      />
                    </div>
                  </a>
                  <a href="#" className="dropdown-item">
                    Exposure
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck1"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck1"
                        className="custom-control-label"
                      />
                    </div>
                  </a> */}
                   {/*  <a
                      onClick={() => setShowRules(true)}
                      className="dropdown-item"
                    >
                      Rules
                    </a> */}
                    <a
                      onClick={() => {
                        localStorage.clear();
                        setTimeout(() => {
                          window.location.href = "/";
                          window.location.reload();
                          dispatch({
                            type: "accountData/logOut",
                            payload: {},
                          });
                        }, 500);
                      }}
                      className="dropdown-item mt-2 text-danger"
                    >
                      <b>Logout</b>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          {window?.innerWidth > 786 && (
            <div className="dropdown ">
              <a
                data-toggle="dropdown"
                className="user-name ms-3 d-none d-xl-block dropdown-toggle"
                id="react-aria242214943-2"
                aria-expanded="false"
              >
                {userData?.username}
                <i className="fas fa-chevron-down ms-1" />
              </a>
              <div className="dropdown-menu pookie">
                <a href="/home" className="dropdown-item">
                  Home
                </a>
                <a href="/reports/accountstatement" className="dropdown-item">
                  Account Statement
                </a>
                <a href="/reports/profitloss" className="dropdown-item">
                  Profit Loss Report
                </a>
                <a href="/reports/bethistory" className="dropdown-item">
                  Bet History
                </a>
                <a href="/reports/unsetteledbet" className="dropdown-item">
                  Unsetteled Bet
                </a>
                <a href="/reports/casinoresults" className="dropdown-item">
                  Casino Report History
                </a>
                <a href="/setting/changebtnvalue" className="dropdown-item">
                  Set Button Values
                </a>

                <a href="/settings/security-auth" className="dropdown-item">
                  Security Auth Verification
                </a>
                <a href="/setting/changepassword" className="dropdown-item">
                  Change Password
                </a>
                {/*    <a href="#" className="dropdown-item">
                    Balance
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck"
                        className="custom-control-label"
                      />
                    </div>
                  </a>
                  <a href="#" className="dropdown-item">
                    Exposure
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck1"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck1"
                        className="custom-control-label"
                      />
                    </div>
                  </a> */}
                <a href="/rules" className="dropdown-item">
                  Rules
                </a>
                <a
                  onClick={() => {
                    window.localStorage.clear();

                    dispatch({
                      type: "accountData/logOut",
                      payload: {},
                    });
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 1000);
                  }}
                  className="dropdown-item mt-2 text-danger"
                >
                  <b>Logout</b>
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="search-box-container d-xl-none">
          <div className="search-box">
            <input
              type="search"
              placeholder="Search here"
              className={
                "form-control " + (isSearchOpen ? "search-input-show" : "")
              }
              defaultValue=""
              onChange={handleSearch}
            />

            <a
              onClick={() => {
                setIsSearchOpen(!isSearchOpen);
                setSearchResults([]);
              }}
            >
              <i className="fas fa-search-plus" />
            </a>
            {searchResults?.length > 0 && (
              <div className="search-list">
                {searchResults?.map((item, index) => (
                  <a href={`/game/${item?.sport?.toLowerCase()}/${item.id}`}>
                    <div className="search-list-item">
                      <div className="search-tournament-name">
                        <b>{item?.eventName}</b>
                      </div>
                      <div className="search-game-date">
                        {moment(item?.eventDate).format(
                          "DD/MM/YYYY HH:MM:SS "
                        )}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>
          <div className="news">
            <marquee scrollamount={3}>{announcement?.announcement}</marquee>
          </div>
          <div className="depowith" />
        </div>
      </div>
      <div className="header-bottom d-none d-xl-block">
        <nav className="navbar navbar-expand">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/sports-book/33">
                Lottery
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/all-sports/4">
                Cricket
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/all-sports/2">
                Tennis
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/all-sports/1">
                Football
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/all-sports/8">
                Table Tennis
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/casino-list/LC/4/22">
                Baccarat
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/casino-list/LC/4/24">
                32 Cards
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/casino-list/LC/4/20">
                Teenpatti
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/casino-list/LC/4/21">
                Poker
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/casino-list/LC/4/27">
                Lucky 7
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {isExposureTableOpen && (
        <ExposureTable onClose={setIsExposureTableOpen} />
      )}
    </section>
  );
};

export default Header;
